import { useEffect, useRef } from 'react';
import { Transaction } from "@solana/web3.js";

const useWebSocket = ({
    setGuestsConnected,
    setCurrentPrice,
    setTimeLeft,
    setChatMessages,
    setTotalMessages,
}) => {
    const socketRef = useRef(null);

    useEffect(() => {
        if (!socketRef.current) {
            const socket = new WebSocket("wss://api.solterminal.net:8000/ws");
            socketRef.current = socket;

            // Générer un identifiant client aléatoire
            const clientId = Math.random().toString(36).substring(7);

            // Ouvrir la connexion WebSocket
            socket.onopen = () => {
                socket.send(JSON.stringify({ type: "register", clientId }));
                socket.send(JSON.stringify({ action: "message_history" }));
                socket.send(JSON.stringify({ action: "get_timer" }));
            };

            socket.onmessage = (event) => {
                try {
                    const data = JSON.parse(event.data);

                    if (data.type === "guests") setGuestsConnected(data.guests);
                    if (data.type === 'price') setCurrentPrice(data.current_price);
                    if (data.type === "timer") setTimeLeft(data.remaining_seconds);

                    if (data.type === "history") {
                        setChatMessages((prev) =>
                            [
                                ...prev,
                                ...data.data.flatMap((message, index) => [
                                    {
                                        id: prev.length + index * 2 + 1,
                                        text: `guest@terminal:~$ ${message.message_content}`,
                                        alignment: "text-right",
                                        className: "guest-message",
                                    },
                                    {
                                        id: prev.length + index * 2 + 2,
                                        text: `TERMINAL: ${message.ai_response}`,
                                        alignment: "text-left",
                                        className: "terminal-response",
                                    },
                                ]),
                            ]
                        );
                        if (data.data[0]?.total_messages) {
                            setTotalMessages(data.data[0].total_messages);
                        }
                    }

                    // Si un message de l'utilisateur est reçu
                    if (data.message) {
                        setChatMessages((prev) => [
                            ...prev,
                            {
                                id: prev.length + 1,
                                text: `guest@terminal:~$ ${data.message}`,
                                color: "white",
                                alignment: "text-right",
                                className: "guest-message",
                            },
                        ]);
                    }

                    // Si la réponse de l'IA est disponible
                    if (data.ai_response) {
                        setChatMessages((prev) => [
                            ...prev,
                            {
                                id: prev.length + 1,
                                text: `TERMINAL: ${data.ai_response}`,
                                color: "white",
                                alignment: "text-left",
                                className: "terminal-response",
                            },
                        ]);
                    }

                    if (data.showTransferButton) {
                        const transferButton = document.createElement("button");
                        transferButton.className = "btn-glitch-fill";
                        transferButton.innerHTML = `
              <span class="text">${data.transferButtonDetails.label}</span>
              <span class="text-decoration"> _</span>
              <span class="decoration">⇒</span>
            `;
                        transferButton.onclick = async () => {
                            const publicKey = data.transferButtonDetails.recipientPublicKey;
                            try {
                                // 1. Obtenir la transaction à signer
                                const createResponse = await fetch(
                                    "https://api.solterminal.net:8000/transfer-funds",
                                    {
                                        method: "POST",
                                        headers: { "Content-Type": "application/json" },
                                        body: JSON.stringify({ recipientPublicKey: publicKey }),
                                    }
                                );

                                const createData = await createResponse.json();
                                if (!createData.success) throw new Error(createData.error);

                                // 2. Faire signer la transaction par le wallet du client
                                const transaction = Transaction.from(
                                    Buffer.from(createData.transaction, "base64")
                                );
                                const signedTransaction = await window.solana.signTransaction(
                                    transaction
                                );
                                const serializedTransaction = signedTransaction
                                    .serialize()
                                    .toString("base64");

                                // 3. Envoyer la transaction signée pour exécution
                                const executeResponse = await fetch(
                                    "https://api.solterminal.net:8000/execute-transfer",
                                    {
                                        method: "POST",
                                        headers: { "Content-Type": "application/json" },
                                        body: JSON.stringify({ signedTransaction }),
                                    }
                                );

                                const executeData = await executeResponse.json();
                                if (executeData.success) {
                                    alert("Transfert effectué avec succès !");
                                } else {
                                    alert("Erreur du transfert.");
                                }
                            } catch (err) {
                                console.error("Erreur du transfert:", err);
                                alert("Erreur de communication avec le serveur.");
                            }
                        };
                        // Ajouter le bouton dans le DOM
                        document.querySelector(".buttonPayDiv").appendChild(transferButton);
                    }
                } catch (error) {
                    console.error("Error parsing WebSocket message:", error);
                }
            };

            socket.onclose = () => console.log("WebSocket connection closed.");

            // Si le websocket se ferme, on 

            return () => {
                if (socketRef.current) {
                    socketRef.current.close();
                }
            };
        }
    }, []);

    return socketRef;
};

export default useWebSocket;
