import React from 'react';

const SocialNetworkButtons = () => {
  return (
    <ul className="example-2">
      <li className="icon-content">
        <a href="https://t.me/sol_Terminal" aria-label="Telegram" data-social="telegram" target="_blank" rel="noreferrer">
          <div className="filled"></div>
          <svg version="1.1" viewBox="0 0 100 100">
            <path d="M95,9.9c-1.3-1.1-3.4-1.2-7-0.1c0,0,0,0,0,0c-2.5,0.8-24.7,9.2-44.3,17.3c-17.6,7.3-31.9,13.7-33.6,14.5 c-1.9,0.6-6,2.4-6.2,5.2c-0.1,1.8,1.4,3.4,4.3,4.7c3.1,1.6,16.8,6.2,19.7,7.1c1,3.4,6.9,23.3,7.2,24.5c0.4,1.8,1.6,2.8,2.2,3.2 c0.1,0.1,0.3,0.3,0.5,0.4c0.3,0.2,0.7,0.3,1.2,0.3c0.7,0,1.5-0.3,2.2-0.8c3.7-3,10.1-9.7,11.9-11.6c7.9,6.2,16.5,13.1,17.3,13.9 c0,0,0.1,0.1,0.1,0.1c1.9,1.6,3.9,2.5,5.7,2.5c0.6,0,1.2-0.1,1.8-0.3c2.1-0.7,3.6-2.7,4.1-5.4c0-0.1,0.1-0.5,0.3-1.2 c3.4-14.8,6.1-27.8,8.3-38.7c2.1-10.7,3.8-21.2,4.8-26.8c0.2-1.4,0.4-2.5,0.5-3.2C96.3,13.5,96.5,11.2,95,9.9z M30,58.3l47.7-31.6 c0.1-0.1,0.3-0.2,0.4-0.3c0,0,0,0,0,0c0.1,0,0.1-0.1,0.2-0.1c0.1,0,0.1,0,0.2-0.1c-0.1,0.1-0.2,0.4-0.4,0.6L66,38.1 c-8.4,7.7-19.4,17.8-26.7,24.4c0,0,0,0,0,0.1c0,0-0.1,0.1-0.1,0.1c0,0,0,0.1-0.1,0.1c0,0.1,0,0.1-0.1,0.2c0,0,0,0.1,0,0.1 c0,0,0,0,0,0.1c-0.5,5.6-1.4,15.2-1.8,19.5c0,0,0,0,0-0.1C36.8,81.4,31.2,62.3,30,58.3z" fill="currentColor"></path>
          </svg>
        </a>
      </li>
      <li className="icon-content">
        <a href="https://x.com/solTerminal_" aria-label="X" data-social="X" target="_blank" rel="noreferrer">
          <div className="filled"></div>
          <svg version="1.1" viewBox="0 0 100 100">
            <path d="M100,19.1c-3.7,1.6-7.6,2.7-11.7,3.2c4.2-2.5,7.4-6.5,8.9-11.2c-3.9,2.3-8.2,4-12.8,4.9 c-3.7-3.9-8.9-6.3-14.7-6.3c-11.1,0-20.1,9-20.1,20.1c0,1.6,0.2,3.2,0.5,4.7c-16.7-0.8-31.5-8.9-41.4-21.2 c-1.7,2.9-2.7,6.3-2.7,9.9c0,6.9,3.5,13,8.8,16.6c-3.2-0.1-6.2-1-8.8-2.4c0,0.1,0,0.1,0,0.2c0,9.6,6.8,17.6,15.8,19.4 c-1.7,0.5-3.5,0.8-5.4,0.8c-1.3,0-2.6-0.1-3.8-0.4c2.6,8.1,10.1,14,19,14.2c-7,5.5-15.8,8.8-25.4,8.8c-1.7,0-3.4-0.1-5-0.3 c9,5.8,19.8,9.2,31.4,9.2c37.7,0,58.3-31.3,58.3-58.3c0-0.9,0-1.9-0.1-2.8C95.6,26.7,98.1,23.1,100,19.1z" fill="currentColor"></path>
          </svg>
        </a>
      </li>
      <li className="icon-content">
        <a href="https://github.com/" aria-label="GitHub" data-social="github" target="_blank" rel="noreferrer">
          <div className="filled"></div>
          <svg version="1.1" viewBox="0 0 100 100">
            <path d="M50,0C22.4,0,0,22.4,0,50c0,22.1,14.3,40.9,34.2,47.6c2.5,0.5,3.4-1.1,3.4-2.4c0-1.2,0-5.4,0-9.9 c-13.9,3-16.8-6.7-16.8-6.7c-2.3-5.8-5.6-7.4-5.6-7.4c-4.6-3.2,0.3-3.1,0.3-3.1c5.1,0.4,7.8,5.2,7.8,5.2 c4.5,7.7,11.8,5.5,14.7,4.2c0.5-3.3,1.8-5.5,3.3-6.8c-11.6-1.3-23.8-5.8-23.8-25.8c0-5.7,2-10.4,5.3-14.1 c-0.5-1.3-2.3-6.7,0.5-14c0,0,4.5-1.4,14.7,5.5c4.3-1.2,8.9-1.8,13.5-1.8c4.6,0,9.2,0.6,13.5,1.8 c10.2-6.9,14.7-5.5,14.7-5.5c2.8,7.3,1,12.7,0.5,14c3.3,3.7,5.3,8.4,5.3,14.1c0,20.1-12.2,24.5-23.8,25.8 c1.9,1.6,3.6,4.8,3.6,9.7c0,7,0,12.7,0,14.4c0,1.3,0.9,2.9 3.4,2.4C85.7,90.9,100,72.1,100,50C100,22.4,77.6,0,50,0z" fill="currentColor"></path>
          </svg>
        </a>
      </li>
    </ul>
  );
};

export default SocialNetworkButtons;